import { hide, show, toggle } from 'slidetoggle';

if( $('.js-show-references').length )
{
  const btn = document.querySelector('.js-show-references');
  btn.addEventListener('click', () => {
    event.preventDefault();
    toggle('.references__hide', {
      miliseconds: 300,
      transitionFunction: 'ease',
      onOpen: () => {
        $(".js-show-references").addClass("opened");
       },
       onClose: () => {
         $(".js-show-references").removeClass("opened");
       },
    });
  });
}
