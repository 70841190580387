$('#file-upload').change(function() {
  var limit = 5; // MB
  var file = $('#file-upload')[0].files[0]
  var fileName = file.name
  var fileSize = (file.size / 1024 / 1024).toFixed(2)
  var $label = $(this).prev('label')

  if(fileSize > limit) {
    alert('Soubor může mít maximálně '+limit+'MB. Současná velikost: '+ fileSize + 'MB')
    return false
  }

  $label.text(fileName)
});
