import GLightbox from 'glightbox'

if( $('.giftModal').length ){
  var is_modal_show = sessionStorage.getItem('alreadyShow');
  if(is_modal_show != 'alredy shown'){
    const myTimeout = setTimeout(callModal, 5000);
    sessionStorage.setItem('alreadyShow','alredy shown');
  }
}

var lightbox = GLightbox();
var lightboxInlineIframe = GLightbox({
  selector: '.giftModal',
  touchNavigation: false,
  slideEffect: 'none',
  draggable: false,
  skin: 'modal giftmodalwrapper',
});

function callModal() {
  lightboxInlineIframe.open();
}
