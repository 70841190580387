/**
 * Toggle Nav
 * ======================================
 * - toggle class on body
 */

const ELEMENTS = '.togglenav__button'
const TOGGLE_CLASS = 'nav-is-open'

class ToggleNav {
  constructor() {
    this.elements = document.querySelectorAll(ELEMENTS)

    if (!this.elements) {
      return false
    }

    this.elements.forEach((el) => {
      el.addEventListener('click', this.toggleNav, false)
      el.addEventListener('touchstart', this.toggleNav, false)
    })
  }

  toggleNav(e) {
    document.body.classList.toggle(TOGGLE_CLASS)
    document.body.classList.toggle('lock')

    e.preventDefault()
  }
}

new ToggleNav()
