import "./modules/Animate";
import "./modules/ToggleNav";
import "./modules/custom-selectbox";
import "./modules/cookies";
import "./modules/ZipModal";
import "./modules/GiftModal";
import "./modules/videoModal";
import "./modules/AjaxForm";
import "./modules/LightBox";
import "./modules/FileUpload";
import "./modules/ReferencesButton";
import "./modules/Show";
import "./modules/Fancybox";

var swiper = new Swiper(".references__slider", {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
            500: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          767: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1200: {
        slidesPerView: 4,
        spaceBetween: 65,
     },
    },
});

$(document).ready(function() {

    $(".tile2kk").on( "click", function() {
        $(".form select").val("Byt 2kk").change();
    });
    $(".tile3kk").on( "click", function() {
        $(".form select").val("Byt 3kk").change();
    });
    $(".tile4kk").on( "click", function() {
        $(".form select").val("Byt 4kk").change();
    });

    $(".tilerd100").on( "click", function() {
        $(".form select").val("RD do 100m²").change();
    });
    $(".tilerd150").on( "click", function() {
        $(".form select").val("RD do 150m²").change();
    });
    $(".tilerd200").on( "click", function() {
        $(".form select").val("RD do 200m²").change();
    });

});
