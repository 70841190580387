/**
 * Animate
 * ======================================
 * - add class to element in viewport
 * - support custom animation delay via [animate-delay] html attribute
 * - support custom visible ratio via [animate-ratio] html attribute
 */

const RATIO = '0.75'
const LOAD_RATIO = '1'
const ELEMENTS = '.animate'
const VISIBLE_CLASS = 'animate--visible'

class Animate {
	constructor() {
		this.sections = document.querySelectorAll(ELEMENTS)

    window.addEventListener('scroll', () => this.scrollHandler(RATIO), false)

		this.scrollHandler(LOAD_RATIO)
	}

	getDelay = value => {
		if (value === null) {
			return 0
		} else if (value.includes('.')) {
			return value * 1000
		} else {
			return parseInt(value)
		}
	}

	scrollHandler = (CUSTOM_RATIO) => {
		if (!document.querySelectorAll(ELEMENTS + ':not(.' + VISIBLE_CLASS + ')')) return

		for (const section of this.sections) {
			const delay = this.getDelay(section.getAttribute('animate-delay'))
			const ratio = section.getAttribute('animate-ratio') ? section.getAttribute('animate-ratio') : CUSTOM_RATIO

			if (
				section.getBoundingClientRect().top <= window.innerHeight * ratio &&
				section.getBoundingClientRect().top > 0
			) {
				setTimeout(() => {
					section.classList.add(VISIBLE_CLASS)
				}, delay)
			}
		}
	}
}

new Animate()

