import Splide from '@splidejs/splide';

const EL = '.js-show'
const ACTIVE_CLASS = 'is-active'

if (document.querySelector(EL)) {
  const slider = new Splide( EL, {
    type: 'loop',
    perMove: 1,
    perPage: 4,
    autoWidth: true,
    width: 700,
    gap: 50,
    pagination: false
  } ).mount();
}
