import GLightbox from 'glightbox'

/**
 * ZipModal
 * ======================================
 * - show popup based on zip code
 */

const INPUT = '.js-ZipModal'


class ZipModal {
  constructor() {
    this.input = document.querySelector(INPUT)

    if (!this.input) {
      return false
    }

    this.form = this.input.closest('form')

    this.form.addEventListener('submit', this.submit, false)
  }

  submit = (e) => {
    const text = document.all.kraj.value;
    const address = document.all.cela_adresa.value;
    document.getElementById("modalAddress").innerHTML = address;
    document.getElementById("modalAddressNo").innerHTML = address;

    if(text === "Středočeský kraj" || text === "Hlavní město Praha" || address.includes("Brno")){
      this.openModal('#glightbox-modal-1');

    } else {
      this.openModal('#glightbox-modal-2');
    }

    e.preventDefault()
  }

  openModal = (id) => {
    const CLOSE_BUTTON = '.js-close-lightbox'
    const content = document.querySelector(id+' > div').cloneNode(true)

    const modal = GLightbox({
      skin: 'modal',
      elements: [
          {
              'content': content
          },
      ],
    });
    modal.open();

    // close
    document.querySelectorAll(CLOSE_BUTTON).forEach((el) => {
      el.addEventListener('click', function(){
        modal.close()
      })
    })
  }
}

new ZipModal()
